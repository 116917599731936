<template>
  <div class="iss-main" ref="mainRef">
    <div class="flex">
      <h3><b>推广者数据</b></h3>
      <div @click="handleExData" class="cursor-p">
        <ExportOutlined class="icon-color" /><span> 导出列表 </span>
      </div>
    </div>
    <grid
      :url="url"
      :columns="columns"
      :code="$route.path"
      :scroll="{ x: 900, y: gridHeight }"
      :search="{ pointsSort: sort }"
      :url-params="{ promoteId: configData.id }"
      @change="tableTab"
    >
      <!--      <template #promoterUserName="{ text }">-->
      <!--        <div class="iss-name-wrap">-->
      <!--          <div :title="text">{{ text }}</div>-->
      <!--          <div>-->
      <!--            <PartitionOutlined-->
      <!--              class="icon-color cursor-p"-->
      <!--              @click="handleClickLink(record)"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->
      <template #conversionNumber="{ record }">
        <span v-if="configData.resourceType === 'article'">-</span>
        <span v-else-if="configData.promoteUrlType === '0'">-</span>
        <span v-else>{{ record.conversionNumber }}</span>
      </template>
      <template #communicateCount="{ record }">
        <span v-if="configData.promoteType === 5 || configData.isFission">{{
          record.communicateCount
        }}</span>
        <span v-else>-</span>
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>

    <a-drawer
      title="归属人"
      placement="right"
      v-model:visible="drawerVisible"
      @close="drawerVisible = false"
      width="1100px"
      destroyOnClose
      :getContainer="mainRef"
    >
      <detail-drawer
        :marketing-user="activeItem"
        :promote-user-id="activeItem.promoteUserId"
      />
    </a-drawer>
    <a-modal
      title="传播链路"
      v-model:visible="modalVisible"
      width="736px"
      :centered="true"
      @ok="modalVisible = false"
    >
      <ModalLink></ModalLink>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, ref } from 'vue';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
import { local } from '@/utils/storage';
import { downloadExcel } from '@/utils';
import Operation from '@/components/operation';
import DetailDrawer from '../detailDrawer';
import ModalLink from '../modalLink';
import iRadarApi from '@/api/iRadar';
import { Drawer, Modal, message } from 'ant-design-vue';
import { ExportOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    Grid,
    Operation,
    ExportOutlined,
    DetailDrawer,
    ADrawer: Drawer,
    ModalLink,
    AModal: Modal,
  },
  setup() {
    // const route = useRoute();
    // const da = route;;
    const mainRef = ref();
    const state = reactive({
      drawerVisible: false,
      modalVisible: false,
      activeItem: {},
      sorterParmas: '',
      sort: '',
    });
    return {
      mainRef,
      ...toRefs(state),
      gridHeight: document.body.clientHeight - 458,
      configData: local.get('configData'),
      url: promoteApi.promoteDataPageUrl,
      columns: [
        {
          dataIndex: 'promoterUserName',
          title: '推广者姓名',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'mobileNum',
          title: '推广者手机',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'promoterUserCompany', title: '推广者公司', width: 160 },
        { dataIndex: 'points', title: '积分', sorter: true },
        {
          dataIndex: 'communicateCount',
          slots: { customRender: 'communicateCount' },
          title: '传播人数',
          width: 100,
        },
        {
          dataIndex: 'conversionNumber',
          slots: { customRender: 'conversionNumber' },
          title: '转化人数',
          width: 100,
        },
        { dataIndex: 'visitorNumber', title: '访问人数', width: 100 },
        { dataIndex: 'visitCount', title: '访问次数' },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'user:view',
          fnClick: record => {
            state.drawerVisible = true;
            state.activeItem = record;
          },
        },
        {
          type: 'control',
          label: '导出',
          icon: 'ExportOutlined',
          permission: 'live:control',
          fnClick: ({ id, promoterUserName }) => {
            promoteApi
              .promoteDataExportItem('contact:export', id)
              .then(({ data }) => {
                promoterUserName = promoterUserName.replace(/\./g, '');
                let promoteName = local
                  .get('configData')
                  .promoteName.replace(/\./g, '');
                downloadExcel(
                  `${promoterUserName}_${promoteName}_推广数据.xlsx`,
                  data
                );
                message.success('操作成功！');
              });
          },
        },
      ],
      handleExData: () => {
        promoteApi
          .promoteExportDataByPromoteId(
            'contact:export',
            local.get('configData').id
          )
          .then(({ data }) => {
            downloadExcel('推广数据表.xlsx', data);
            message.success('操作成功！');
          });
      },
      handleClickLink(record) {
        iRadarApi.getMarketingCommunicateChain('', {
          communicateUserId: record.promoteUserId,
          promoteId: record.promoteId,
          rootUserId: record.rootUserId,
        });
        state.modalVisible = true;
      },

      // todo 排序的方法 拿到参数字段传到 url 里面
      tableTab: (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
          state.sort = 'asc';
        } else {
          state.sort = 'desc';
        }
        state.sorterParmas = sorter.field;
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  //height: calc(100vh - 301px);
  .exData {
    text-align: right;
  }
  :deep(.ant-drawer-body) {
    padding: 0;
  }
}
.iss-name-wrap {
  display: flex;
  div:nth-of-type(1) {
    width: 66px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}
.icon-color {
  color: #5979f8;
}
</style>
