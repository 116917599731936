<template>
  <div class="iss-main" ref="mainRef">
    <div class="flex">
      <h3><b>传播者数据</b></h3>
      <!--      <div @click="handleExData" class="cursor-p">-->
      <!--        <ExportOutlined class="icon-color" /><span> 导出列表 </span>-->
      <!--      </div>-->
    </div>
    <grid
      :url="url"
      :columns="columns"
      :code="$route.path"
      :scroll="{ x: 900, y: gridHeight }"
      :search="{ pointsSort: sort }"
      :url-params="{ promoteId: configData.id }"
      @change="tableTab"
    >
      <template #userName="{ text, record }">
        <div class="iss-name-wrap">
          <div :title="text">{{ text }}</div>
          <div>
            <PartitionOutlined
              class="icon-color cursor-p"
              @click="handleClickLink(record)"
            />
          </div>
        </div>
      </template>
      <template #conversionNumber="{ record }">
        <span v-if="configData.resourceType === 'article'">-</span>
        <span v-else-if="configData.promoteUrlType === '0'">-</span>
        <span v-else>{{ record.conversionNumber }}</span>
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
    <a-modal
      title="传播链路"
      v-model:visible="modalVisible"
      width="736px"
      :centered="true"
      @ok="modalVisible = false"
    >
      <modal-link :list="modalList"></modal-link>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, ref } from 'vue';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
import { local } from '@/utils/storage';
import { downloadExcel } from '@/utils';
import Operation from '@/components/operation';
import { message, Modal } from 'ant-design-vue';
import { PartitionOutlined /*ExportOutlined*/ } from '@ant-design/icons-vue';
import iRadarApi from '@/api/iRadar';
import ModalLink from '../modalLink';
export default {
  components: {
    Grid,
    Operation,
    PartitionOutlined,
    AModal: Modal,
    ModalLink,
    // ExportOutlined,
  },
  setup() {
    // const route = useRoute();
    // const da = route;;
    const configData = local.get('configData');
    const mainRef = ref();
    const state = reactive({
      drawerVisible: false,
      modalVisible: false,
      activeItem: {},
      modalList: [],
      sorterParmas: '',
      sort: '',
    });
    return {
      mainRef,
      configData,
      ...toRefs(state),
      gridHeight: document.body.clientHeight - 458,
      url: iRadarApi.pageUrl,
      columns: [
        {
          dataIndex: 'userName',
          title: '姓名',
          width: 120,
          slots: { customRender: 'userName' },
        },
        {
          dataIndex: 'mobileNum',
          title: '手机',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'companyName',
          title: '公司',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'parentUserName',
          title: '上级',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteUserName',
          title: '推广者',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '传播时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'points',
          title: '积分',
          width: 100,
          ellipsis: true,
          sorter: true,
        },
        {
          dataIndex: 'uv',
          title: '访问人数',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'pv',
          title: '访问次数',
          ellipsis: true,
        },
      ],
      options: [
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'user:view',
          fnClick: record => {
            state.drawerVisible = true;
            state.activeItem = record;
          },
        },
      ],
      handleExData: () => {
        promoteApi
          .promoteExportDataByPromoteId(
            'contact:export',
            local.get('configData').id
          )
          .then(({ data }) => {
            downloadExcel('推广数据表.xlsx', data);
            message.success('操作成功！');
          });
      },
      handleClickLink(record) {
        iRadarApi
          .getMarketingCommunicateChain('', {
            communicateUserId: record.id,
            promoteId: local.get('configData').id,
            rootUserId: record.rootUserId,
          })
          .then(res => {
            state.modalList = res;
            state.modalVisible = true;
          });
      },

      tableTab: (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
          state.sort = 'asc';
        } else {
          state.sort = 'desc';
        }
        state.sorterParmas = sorter.field;
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  //height: calc(100vh - 301px);
  .exData {
    text-align: right;
  }
}
.iss-name-wrap {
  display: flex;
  div:nth-of-type(1) {
    width: 66px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}
.icon-color {
  color: #5979f8;
}
</style>
