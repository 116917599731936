<template>
  <div class="iss-main" ref="mainRef">
    <div class="flex">
      <h3><b>留资数据</b></h3>
      <!--      <div @click="handleExData" class="cursor-p">-->
      <!--        <ExportOutlined class="icon-color" /><span> 导出列表 </span>-->
      <!--      </div>-->
    </div>

    <grid :scroll="{ x: 1200, y: gridHeight }" :url="url" :columns="columns">
    </grid>
  </div>
</template>
<script>
import { reactive, toRefs, ref } from 'vue';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
import { local } from '@/utils/storage';
import { downloadExcel } from '@/utils';
import { message } from 'ant-design-vue';
// import { ExportOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    Grid,
    // ExportOutlined,
  },
  setup() {
    // const route = useRoute();
    // const da = route;;
    const configData = local.get('configData');
    const mainRef = ref();
    const state = reactive({
      drawerVisible: false,
      activeItem: {},
    });
    return {
      mainRef,
      ...toRefs(state),
      gridHeight: document.body.clientHeight - 458,
      configData,
      url: `/marketing/marketingUserRetention/submitForm/data/${configData.id}/${configData.promoteType}/0`,
      columns: [
        {
          dataIndex: 'userName',
          title: '姓名',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'mobileNum',
          title: '手机',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'companyName',
          title: '公司',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'communicateUserName',
          title: '传播者',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteUserName',
          title: '推广者',
          ellipsis: true,
        },
      ],
      handleExData: () => {
        promoteApi
          .promoteExportDataByPromoteId(
            'contact:export',
            local.get('configData').id
          )
          .then(({ data }) => {
            downloadExcel('推广数据表.xlsx', data);
            message.success('操作成功！');
          });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  //height: calc(100vh - 301px);
  .exData {
    text-align: right;
  }
}
.iss-name-wrap {
  display: flex;
  div:nth-of-type(1) {
    width: 66px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}
.icon-color {
  color: #5979f8;
}
</style>
