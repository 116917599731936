<template>
  <div class="iss-main-one">
    <a-row :gutter="20">
      <a-col :span="19">
        <h3>
          推广内容名称：<b>{{ configData.promoteName }}</b>
        </h3>
      </a-col>
      <a-col :span="5">
        <p style="color: #7b7676">创建时间：{{ configData.createTime }}</p>
      </a-col>
    </a-row>
    <div class="text-color">
      <ul class="data-board">
        <li>
          <div>推广人数</div>
          <div>{{ configData.promoterNumber || 0 }}</div>
        </li>
        <li v-if="configData.promoteType === 5 || configData.isFission">
          <div>传播人数</div>
          <div>{{ configData.communicateUserCount || 0 }}</div>
        </li>
        <li v-else>
          <div class="ash-color">传播人数</div>
          <div class="ash-color">-</div>
        </li>
        <li>
          <div>访问人数</div>
          <div>{{ configData.visitorNumber || 0 }}</div>
        </li>
        <li>
          <div>访问次数</div>
          <div>{{ configData.visitCount || 0 }}</div>
        </li>
        <li
          v-if="
            configData.promoteType === 1 ||
            (configData.promoteType === 2 &&
              configData.resourceType === 'file') ||
            (configData.promoteType === 5 && configData.promoteUrlType === '1')
          "
        >
          <div>转化人数</div>
          <div>{{ configData.conversionNumber || 0 }}</div>
        </li>
        <li v-else>
          <div class="ash-color">转化人数</div>
          <div class="ash-color">-</div>
        </li>
      </ul>
    </div>
  </div>

  <a-tabs v-model:activeKey="activeKey" class="iss-tab">
    <a-tab-pane key="1" tab="推广者数据">
      <a-pane-one />
    </a-tab-pane>

    <a-tab-pane
      key="2"
      tab="传播者数据"
      v-if="configData.promoteType === 5 || configData.isFission"
    >
      <a-pane-two />
    </a-tab-pane>

    <a-tab-pane
      key="3"
      tab="留资数据"
      v-if="
        configData.promoteType === 1 ||
        (configData.promoteType === 5 && configData.promoteUrlType === '1')
      "
    >
      <a-pane-three />
    </a-tab-pane>
  </a-tabs>
</template>
<script>
// import promoteApi from '@/api/promote';
import { local } from '@/utils/storage';
// import { downloadExcel } from '@/utils';
import { Row, Col, Tabs } from 'ant-design-vue';
import { reactive, toRefs } from 'vue';
import PaneOne from './detailPane/paneOne';
import PaneTwo from './detailPane/paneTwo';
import PaneThree from './detailPane/paneThree';

export default {
  components: {
    // Grid,
    ARow: Row,
    ACol: Col,
    // Operation,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    APaneOne: PaneOne,
    APaneTwo: PaneTwo,
    APaneThree: PaneThree,
    // TeamOutlined,
    // AStatistic: Statistic,
    // EyeOutlined,
    // RedoOutlined,
  },
  setup() {
    // const route = useRoute();
    // const da = route;
    const state = reactive({
      activeKey: '1',
      configData: local.get('configData'),
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.iss-tab {
  &:deep(.ant-tabs-bar) {
    margin: 0 16px;
  }
}
.iss-main-one {
  margin: 16px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px #d6e2ff;
}
.iss-main {
  //height: calc(100vh - 301px);
  .exData {
    text-align: right;
  }
}
.data-board {
  border-top: 1px #e5e6eb solid;
  padding-top: 12px;
  display: flex;
  margin: 0;
  li {
    display: flex;
    align-items: center;
    & > div:nth-of-type(1) {
      margin-right: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
    & > div:nth-of-type(2) {
      font-size: 18px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      margin-right: 48px;
    }
  }
}
</style>
